import React from "react";
import { Container, Typography, Button, Box, Paper } from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { useNavigate, useLocation } from "react-router-dom";

const RegistrationSuccess = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const email =
    new URLSearchParams(location.search).get("email") || "example@example.com";

  const handleGoToHome = () => {
    navigate("/");
  };

  const handleGoToDownload = () => {
    navigate("/download");
  };

  return (
    <Container maxWidth="sm" style={{ padding: "20px", height: "90%" }}>
      <Paper elevation={3} style={{ padding: "20px", borderRadius: "8px" }}>
        <Box display="flex" justifyContent="center" mb={2}>
          <CheckCircleIcon style={{ fontSize: "64px", color: "green" }} />
        </Box>
        <Typography variant="h4" gutterBottom align="center" color="primary">
          Registration Successful!
        </Typography>
        <Typography variant="body1" gutterBottom align="center">
          Thank you for registering! Please activate your account through the
          email that has been sent to you. If you don't see it in your inbox,
          please check your spam or junk folder.
        </Typography>
        <Typography
          variant="body1"
          gutterBottom
          align="center"
          color="textSecondary"
        >
          Registered email: <strong>{email}</strong>
        </Typography>
        <Box mt={3} display="flex" justifyContent="center">
          <Button
            variant="contained"
            color="primary"
            onClick={handleGoToHome}
            style={{ marginRight: "10px" }}
          >
            Go to Home Page
          </Button>
          <Button
            variant="outlined"
            color="primary"
            onClick={handleGoToDownload}
          >
            Go to Download Page
          </Button>
        </Box>
      </Paper>
    </Container>
  );
};

export default RegistrationSuccess;
