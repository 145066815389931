import React from "react";
import "./App.css";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import AppBar from "./components/AppBar";
import Footer from "./components/Footer";
import Home from "./pages/Home";
import Register from "./pages/Register";
import Ranking from "./pages/Ranking";
import News from "./pages/News";
import AccountSettings from "./pages/AccountSettings";
import Profile from "./pages/Profile";
import Download from "./pages/Download";
import NotFound from "./pages/NotFound";
import LoginPage from "./pages/LoginPage";
import PrivateRoute from "./components/PrivateRoute";
import PublicRoute from "./components/PublicRoute";
import VerifyEmail from "./pages/VerifyEmail";
import StorePage from "./shop/StorePage";
import Panel from "./panel/Dashboard";
import GameGuide from "./pages/GameGuide";
import ChargePoints from "./pages/ChargePoints";
import SuccessPage from "./pages/SuccessPage";
import FailurePage from "./pages/FailurePage";
import RedeemCoupon from "./pages/RedeemCoupon";
import BetaBanner from "./components/BetaBanner";
import RegistrationSuccess from "./pages/RegistrationSuccess";
import ForgotPassword from "./pages/ForgotPassword";


import { createTheme, ThemeProvider } from "@mui/material/styles";
import { AuthProvider } from "./components/AuthContext";

const theme = createTheme({
  palette: {
    primary: {
      main: "#4B0082",
    },
    secondary: {
      main: "#f7a809",
    },
  },
  typography: {
    fontFamily: "Alexandria",
    allVariants: {
      color: "#f7a809",
    },
  },
});

function App() {
  return (
    <ThemeProvider theme={theme}>
      <AuthProvider>
        <Router>
          <BetaBanner />
          <AppBar />
          <Routes>
            {/* صفحات عامة للمستخدمين غير المسجلين */}
            <Route element={<PublicRoute />}>
              <Route path="/login" element={<LoginPage />} />
              <Route path="/register" element={<Register />} />
              <Route path="/forgot-password" element={<ForgotPassword />} />
            </Route>

            {/* صفحات محمية للمستخدمين المسجلين */}
            <Route element={<PrivateRoute />}>
              <Route path="/settings" element={<AccountSettings />} />
              <Route path="/profile" element={<Profile />} />{" "}
              <Route path="/shop" element={<StorePage />} />
              <Route path="/panel" element={<Panel />} />
              <Route path="/charge" element={<ChargePoints />} />
              <Route path="/success" element={<SuccessPage />} />
              <Route path="/cancel" element={<FailurePage />} />
              <Route path="/RedeemCoupon" element={<RedeemCoupon />} />
            </Route>

            {/* صفحات عامة يمكن الوصول إليها من قبل الجميع */}
            <Route path="/" element={<Home />} />
            <Route path="/ranking" element={<Ranking />} />
            <Route path="/news" element={<News />} />
            <Route path="/download" element={<Download />} />
            <Route path="/verify/:token" element={<VerifyEmail />} />
            <Route path="/GameGuide" element={<GameGuide />} />
            <Route
              path="/registration-success"
              element={<RegistrationSuccess />}
            />
            {/* صفحة الخطأ 404 */}
            <Route path="*" element={<NotFound />} />
          </Routes>
          <Footer />
        </Router>
      </AuthProvider>
    </ThemeProvider>
  );
}

export default App;
