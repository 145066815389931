import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import HttpApi from "i18next-http-backend";
import LanguageDetector from "i18next-browser-languagedetector";

// مسح البيانات المخزنة عند تحميل التطبيق أو عند تغيير اللغة
const clearStoredTranslations = () => {
  localStorage.removeItem('i18nextLng'); // مسح اللغة المخزنة في localStorage
  document.cookie = 'i18nextLng=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/'; // مسح اللغة المخزنة في الكوكيز
};

i18n
  .use(HttpApi)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    fallbackLng: "ar",
    supportedLngs: ["en", "ar"],
    detection: {
      order: [
        "localStorage",
        "cookie",
        "navigator",
        "htmlTag",
        "path",
        "subdomain",
      ],
      caches: ["localStorage", "cookie"],
    },
    backend: {
      // إضافة timestamp في المسار لتحميل الترجمات الجديدة بعد كل عملية بناء
      loadPath: "/locales/{{lng}}/translation.json?timestamp=" + new Date().getTime(),
    },
    react: {
      useSuspense: false,
    },
  });

// مسح الترجمات القديمة عند تحميل التطبيق أو عند التغيير
clearStoredTranslations();

// إضافة مستمع لتغيير اللغة لتحديث الترجمات
i18n.on('languageChanged', (lng) => {
  clearStoredTranslations(); // مسح الترجمات القديمة عند تغيير اللغة
});

export default i18n;
