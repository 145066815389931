import React, { useState, useEffect } from "react";
import {
  Container,
  Box,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Snackbar,
  Alert,
  Dialog,
  DialogActions,
  DialogContent,
  Button,
  DialogTitle,
  TextField,
  FormControlLabel,
  Checkbox,
  Grid,
} from "@mui/material";
import axios from "../utils/axiosConfig"; // Ensure correct path
import AddIcon from "@mui/icons-material/Add";

const CouponComponent = () => {
  const [fetchCouponsTrigger, setFetchCouponsTrigger] = useState(false);
  const [coupons, setCoupons] = useState([]);
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("success");

  const [openEditDialog, setOpenEditDialog] = useState(false);
  const [selectedCoupon, setSelectedCoupon] = useState(null);

  const [editedCode, setEditedCode] = useState("");
  const [editedReward, setEditedReward] = useState([{ id: "", quantity: "" }]);
  const [editedDaysValid, setEditedDaysValid] = useState("");
  const [editedUsageLimit, setEditedUsageLimit] = useState("");
  const [editedIsActive, setEditedIsActive] = useState(true);

  const [openCreateDialog, setOpenCreateDialog] = useState(false);
  const [newCode, setNewCode] = useState("");
  const [newReward, setNewReward] = useState([{ id: "", quantity: "" }]);
  const [newDaysValid, setNewDaysValid] = useState("");
  const [newUsageLimit, setNewUsageLimit] = useState("");

  // Fetch coupons
  useEffect(() => {
    const fetchCoupons = async () => {
      try {
        const response = await axios.get("/api/coupons/get-coupons");
        if (Array.isArray(response.data.coupons)) {
          setCoupons(response.data.coupons);
        } else {
          setCoupons([]);
        }
      } catch (error) {
        setSnackbarMessage("فشل تحميل الكوبونات.");
        setSnackbarSeverity("error");
        setShowSnackbar(true);
      }
    };

    fetchCoupons();
  }, [fetchCouponsTrigger]);

  const handleCloseSnackbar = () => {
    setShowSnackbar(false);
  };

  const handleOpenEditDialog = (coupon) => {
    setSelectedCoupon(coupon);

    // تحويل البيانات بحيث تتوافق مع الحقول في الواجهة (id, quantity)
    const parsedRewards = coupon.reward
      ? JSON.parse(coupon.reward).map((reward) => ({
          id: reward.item_code || "", // إذا كانت البيانات تحتوي على item_code، يتم تحويلها إلى id
          quantity: reward.item_count || "", // إذا كانت البيانات تحتوي على item_count، يتم تحويلها إلى quantity
        }))
      : [{ id: "", quantity: "" }];

    // تحديث الحالة بقيم البيانات
    setEditedReward(parsedRewards);
    setEditedCode(coupon.code || "");
    setEditedDaysValid(coupon.daysValid || "");
    setEditedUsageLimit(coupon.usage_limit || "");
    setEditedIsActive(coupon.is_active);
    setOpenEditDialog(true); // فتح نافذة التعديل
  };

  const handleCloseEditDialog = () => {
    setOpenEditDialog(false);
    setSelectedCoupon(null);
  };

  const handleEditSubmit = async () => {
    if (selectedCoupon) {
      // تحويل المكافآت من الشكل الحالي إلى الشكل الصحيح (item_code, item_count)
      const rewards = editedReward.map((reward) => ({
        item_code: reward.id, // تحويل id إلى item_code
        item_count: reward.quantity, // تحويل quantity إلى item_count
      }));

      try {
        const response = await axios.put(
          `/api/coupons/update-coupon/${selectedCoupon.id}`,
          {
            code: editedCode,
            rewards: rewards, // إرسال المصفوفة بالتركيب الصحيح
            daysValid: editedDaysValid,
            usageLimit: editedUsageLimit,
            isActive: editedIsActive,
          }
        );
        setSnackbarMessage(response.data.message);
        setSnackbarSeverity("success");
        setShowSnackbar(true);

        // تفعيل إعادة جلب الكوبونات
        setFetchCouponsTrigger((prevState) => !prevState); // تغيير الحالة لتفعيل إعادة الجلب
      } catch (error) {
        setSnackbarMessage("فشل تعديل الكوبون.");
        setSnackbarSeverity("error");
        setShowSnackbar(true);
      }
    }
    handleCloseEditDialog(); // إغلاق الديلوج بعد إرسال البيانات
  };
  const handleRemoveReward = (index) => {
    const updatedRewards = editedReward.filter((_, i) => i !== index);
    setEditedReward(updatedRewards);
  };

  const handleOpenCreateDialog = () => {
    setOpenCreateDialog(true);
  };

  const handleCloseCreateDialog = () => {
    setOpenCreateDialog(false);
    setNewCode("");
    setNewReward([{ id: "", quantity: "" }]);
    setNewDaysValid("");
    setNewUsageLimit("");
  };

  const handleRewardChange = (index, field, value) => {
    const updatedRewards = [...editedReward];
    updatedRewards[index] = { ...updatedRewards[index], [field]: value };
    setEditedReward(updatedRewards);
  };

  const handleAddReward = () => {
    setNewReward([...newReward, { id: "", quantity: "" }]);
  };

  const handleCreateSubmit = async () => {
    try {
      const response = await axios.post("/api/coupons/create-coupon", {
        code: newCode,
        rewards: newReward.map((reward) => ({
          item_code: reward.id,
          item_count: reward.quantity,
        })),
        daysValid: newDaysValid,
        usageLimit: newUsageLimit,
      });
      setSnackbarMessage(response.data.message);
      setSnackbarSeverity("success");
      setShowSnackbar(true);

      // تفعيل إعادة جلب الكوبونات
      setFetchCouponsTrigger((prevState) => !prevState); // تغيير الحالة لتفعيل إعادة الجلب
    } catch (error) {
      setSnackbarMessage("فشل إنشاء الكوبون.");
      setSnackbarSeverity("error");
      setShowSnackbar(true);
    }
    handleCloseCreateDialog(); // إغلاق الديلوج بعد إرسال البيانات
  };

  const handleDeleteCoupon = async (couponId) => {
    try {
      const response = await axios.delete(
        `/api/coupons/delete-coupon/${couponId}`
      );
      setSnackbarMessage(response.data.message);
      setSnackbarSeverity("success");
      setShowSnackbar(true);

      // تفعيل إعادة جلب الكوبونات
      setFetchCouponsTrigger((prevState) => !prevState); // تغيير الحالة لتفعيل إعادة الجلب
    } catch (error) {
      setSnackbarMessage("فشل حذف الكوبون.");
      setSnackbarSeverity("error");
      setShowSnackbar(true);
    }
  };

  return (
    <Container maxWidth="lg" style={{ direction: "rtl" }}>
      <Box
        sx={{ p: 3, bgcolor: "#ffffff", borderRadius: 1, boxShadow: 1, mb: 2 }}
      >
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Typography variant="h4" gutterBottom>
            إدارة الكوبونات
          </Typography>
          <Button
            variant="contained"
            color="primary"
            onClick={handleOpenCreateDialog}
            sx={{ mb: 2 }}
          >
            إنشاء كوبون جديد
          </Button>
        </Box>
        <TableContainer component={Paper}>
          <Table>
            <TableHead style={{ background: "#5B0052" }}>
              <TableRow>
                <TableCell style={{ color: "#f7a809", textAlign: "center" }}>
                  ID
                </TableCell>
                <TableCell style={{ color: "#f7a809", textAlign: "center" }}>
                  الكود
                </TableCell>
                <TableCell style={{ color: "#f7a809", textAlign: "center" }}>
                  المكافأة
                </TableCell>
                <TableCell style={{ color: "#f7a809", textAlign: "center" }}>
                  صلاحية (أيام)
                </TableCell>
                <TableCell style={{ color: "#f7a809", textAlign: "center" }}>
                  الحد الأقصى للاستخدام
                </TableCell>
                <TableCell style={{ color: "#f7a809", textAlign: "center" }}>
                  عدد الاستخدامات
                </TableCell>
                <TableCell style={{ color: "#f7a809", textAlign: "center" }}>
                  الإجراءات
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {coupons.length > 0 ? (
                coupons.map((coupon) => (
                  <TableRow key={coupon.id}>
                    <TableCell style={{ textAlign: "center" }}>
                      {coupon.id}
                    </TableCell>
                    <TableCell style={{ textAlign: "center" }}>
                      {coupon.code}
                    </TableCell>
                    <TableCell style={{ textAlign: "center" }}>
                      {coupon.reward ? (
                        JSON.parse(coupon.reward).map((item, index) => (
                          <div key={index}>
                            <span>{`Item: ${item.item_code}, Quantity: ${item.item_count}`}</span>
                          </div>
                        ))
                      ) : (
                        <span>لا توجد عناصر</span>
                      )}
                    </TableCell>
                    <TableCell style={{ textAlign: "center" }}>
                      {coupon.daysValid === null
                        ? "غير محدود"
                        : coupon.daysValid}
                    </TableCell>
                    <TableCell style={{ textAlign: "center" }}>
                      {coupon.usage_limit === null
                        ? "غير محدود"
                        : coupon.usage_limit}
                    </TableCell>
                    <TableCell style={{ textAlign: "center" }}>
                      {coupon.times_redeemed}
                    </TableCell>
                    <TableCell
                      style={{
                        textAlign: "center",
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      <Button
                        color="secondary"
                        onClick={() => handleOpenEditDialog(coupon)}
                      >
                        تعديل
                      </Button>
                      <Button
                        color="error"
                        onClick={() => handleDeleteCoupon(coupon.id)}
                      >
                        حذف
                      </Button>
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan={7} style={{ textAlign: "center" }}>
                    لا توجد كوبونات حالياً
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>

      {/* Snackbar */}
      <Snackbar
        open={showSnackbar}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
      >
        <Alert
          onClose={handleCloseSnackbar}
          severity={snackbarSeverity}
          sx={{ width: "100%" }}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>

      {/* Edit Coupon Dialog */}
      <Dialog open={openEditDialog} onClose={handleCloseEditDialog}>
        <DialogTitle>تعديل الكوبون</DialogTitle>
        <DialogContent>
          <TextField
            label="كود الكوبون"
            fullWidth
            value={editedCode || ""}
            onChange={(e) => setEditedCode(e.target.value)}
            sx={{ mb: 2 }}
          />
          {editedReward.map((reward, index) => (
            <Box key={index} sx={{ mb: 2 }}>
              <Grid container spacing={2}>
                <Grid item xs={5}>
                  <TextField
                    label="رمز العنصر"
                    fullWidth
                    value={reward.id || ""} // يجب أن يكون item_code
                    onChange={(e) =>
                      handleRewardChange(index, "id", e.target.value)
                    } // تعديل ID إلى item_code
                    sx={{ mb: 1 }}
                  />
                </Grid>
                <Grid item xs={5}>
                  <TextField
                    label="الكمية"
                    fullWidth
                    type="number"
                    value={reward.quantity || ""} // يجب أن يكون item_count
                    onChange={(e) =>
                      handleRewardChange(index, "quantity", e.target.value)
                    } // تعديل quantity إلى item_count
                  />
                </Grid>
                <Grid
                  item
                  xs={2}
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Button
                    variant="outlined"
                    color="error"
                    onClick={() => handleRemoveReward(index)} // وظيفة لحذف المكافأة
                    sx={{ width: "100%" }}
                  >
                    حذف
                  </Button>
                </Grid>
              </Grid>
            </Box>
          ))}
          <Button
            startIcon={<AddIcon />}
            onClick={() =>
              setEditedReward([...editedReward, { id: "", quantity: "" }])
            }
            sx={{ mb: 2 }}
          >
            إضافة مكافأة
          </Button>
          <TextField
            label="مدة الصلاحية (أيام)"
            fullWidth
            value={editedDaysValid || ""}
            onChange={(e) => setEditedDaysValid(e.target.value)}
            sx={{ mb: 2 }}
          />
          <TextField
            label="الحد الأقصى للاستخدام"
            fullWidth
            value={editedUsageLimit || ""}
            onChange={(e) => setEditedUsageLimit(e.target.value)}
            sx={{ mb: 2 }}
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={editedIsActive}
                onChange={(e) => setEditedIsActive(e.target.checked)}
              />
            }
            label="تفعيل الكوبون"
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseEditDialog}>إغلاق</Button>
          <Button onClick={handleEditSubmit}>تحديث</Button>
        </DialogActions>
      </Dialog>

      {/* Create Coupon Dialog */}
      <Dialog open={openCreateDialog} onClose={handleCloseCreateDialog}>
        <DialogTitle>إنشاء كوبون جديد</DialogTitle>
        <DialogContent>
          <TextField
            label="كود الكوبون"
            fullWidth
            value={newCode}
            onChange={(e) => setNewCode(e.target.value)}
            sx={{ mb: 2 }}
          />
          {newReward.map((reward, index) => (
            <Box key={index} sx={{ mb: 2 }}>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <TextField
                    label="رمز العنصر"
                    fullWidth
                    value={reward.id}
                    onChange={(e) => {
                      const updatedRewards = [...newReward];
                      updatedRewards[index] = {
                        ...updatedRewards[index],
                        id: e.target.value,
                      };
                      setNewReward(updatedRewards);
                    }}
                    sx={{ mb: 1 }}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    label="الكمية"
                    fullWidth
                    type="number"
                    value={reward.quantity}
                    onChange={(e) => {
                      const updatedRewards = [...newReward];
                      updatedRewards[index] = {
                        ...updatedRewards[index],
                        quantity: e.target.value,
                      };
                      setNewReward(updatedRewards);
                    }}
                  />
                </Grid>
              </Grid>
            </Box>
          ))}
          <Button
            startIcon={<AddIcon />}
            onClick={handleAddReward}
            sx={{ mb: 2 }}
          >
            إضافة مكافأة
          </Button>
          <TextField
            label="مدة الصلاحية (أيام)"
            fullWidth
            value={newDaysValid}
            onChange={(e) => setNewDaysValid(e.target.value)}
            sx={{ mb: 2 }}
          />
          <TextField
            label="الحد الأقصى للاستخدام"
            fullWidth
            value={newUsageLimit}
            onChange={(e) => setNewUsageLimit(e.target.value)}
            sx={{ mb: 2 }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseCreateDialog}>إغلاق</Button>
          <Button onClick={handleCreateSubmit}>إنشاء</Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
};

export default CouponComponent;
