import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import {
  Container,
  Box,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  Snackbar,
  Alert,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  MenuItem,
  FormControl,
  InputLabel,
  Select,
} from "@mui/material";
import axios from "../utils/axiosConfig";

const Categories = [
  { name: "العروض والتخفيضات", value: 1 },
  { name: "الأحجار", value: 2 },
  { name: "التطوير", value: 3 },
  { name: "جواهر الغبار", value: 4 },
  { name: "البفات", value: 5 },
  { name: "الشعارات", value: 6 },
  { name: "الزينة", value: 7 },
  { name: "الأجنحة", value: 8 },
  { name: "أخرى", value: 9 },
];

const Shop = () => {
  const { i18n } = useTranslation();
  const [items, setItems] = useState([]);
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("success");

  const [openDialog, setOpenDialog] = useState(false);
  const [itemCode, setItemCode] = useState("");
  const [itemCount, setItemCount] = useState(0);
  const [itemPrice, setItemPrice] = useState(0);
  const [isProcessing, setIsProcessing] = useState(false);
  const [itemDescAr, setItemDescAr] = useState("");
  const [itemDescEn, setItemDescEn] = useState("");
  const [itemCategorie, setItemCategorie] = useState(1);

  const [editItemId, setEditItemId] = useState(null);
  const [editItemCount, setEditItemCount] = useState(0);
  const [editItemPrice, setEditItemPrice] = useState(0);
  const [editItemCategorie, setEditItemCategorie] = useState("");
  const [editItemNameAr, setEditItemNameAr] = useState("");
  const [editItemNameEn, setEditItemNameEn] = useState("");
  const [editItemDescAr, setEditItemDescAr] = useState("");
  const [editItemDescEn, setEditItemDescEn] = useState("");
  const [editItemActive, setEditItemActive] = useState(null);

  useEffect(() => {
    const fetchItems = async () => {
      try {
        const response = await axios.get("/api/shop");
        setItems(response.data);
      } catch (error) {
        console.error("Failed to fetch items:", error);
      }
    };
    fetchItems();
  }, []);

  const handleDeleteItem = async (item_code) => {
    try {
      await axios.delete("/api/panelShop", { data: { item_code } });
      setItems((prevItems) =>
        prevItems.filter((item) => item.item_code !== item_code)
      );
      setSnackbarMessage("تم حذف العنصر بنجاح!");
      setSnackbarSeverity("success");
      setShowSnackbar(true);
    } catch (error) {
      setSnackbarMessage("فشل حذف العنصر.");
      setSnackbarSeverity("error");
      setShowSnackbar(true);
    }
  };

  const handleEditItem = (item) => {
    setEditItemId(item.item_code);
    setEditItemNameAr(item.item_name_ar);
    setEditItemNameEn(item.item_name_en);
    setEditItemDescAr(item.item_desc_ar);
    setEditItemDescEn(item.item_desc_en);
    setEditItemCount(item.item_count);
    setEditItemPrice(item.item_point);
    setEditItemCategorie(item.item_categorie);
    setEditItemActive(item.is_active);
  };

  const handleCancelEdit = () => {
    setEditItemId(null);
    setEditItemCount(0);
    setEditItemPrice(0);
    setEditItemCategorie("");
  };

  const handleSaveEdit = async () => {
    setIsProcessing(true);
    try {
      await axios.put("/api/panelShop", {
        item_code: editItemId,
        item_name_ar: editItemNameAr,
        item_name_en: editItemNameEn,
        item_desc_ar: editItemDescAr,
        item_desc_en: editItemDescEn,
        item_count: editItemCount,
        item_price: editItemPrice,
        item_categorie: editItemCategorie,
        is_active: editItemActive,
      });
      setItems((prevItems) =>
        prevItems.map((item) =>
          item.item_code === editItemId
            ? {
                ...item,
                item_name_ar: editItemNameAr,
                item_name_en: editItemNameEn,
                item_desc_ar: editItemDescAr,
                item_desc_en: editItemDescEn,
                item_count: editItemCount,
                item_point: editItemPrice,
                item_categorie: editItemCategorie,
                is_active: editItemActive,
              }
            : item
        )
      );
      setSnackbarMessage("تم تحديث العنصر بنجاح.");
      setSnackbarSeverity("success");
      setShowSnackbar(true);
      handleCancelEdit();
    } catch (error) {
      setSnackbarMessage("فشل تحديث العنصر.");
      setSnackbarSeverity("error");
      setShowSnackbar(true);
    } finally {
      setIsProcessing(false);
    }
  };

  const handleAddItem = async () => {
    setIsProcessing(true);
    try {
      // إرسال البيانات إلى الخادم لإضافة العنصر
      const response = await axios.post("/api/panelShop", {
        item_code: itemCode,
        item_count: itemCount,
        item_price: itemPrice,
        item_desc_ar: itemDescAr,
        item_desc_en: itemDescEn,
        item_categorie: itemCategorie,
      });

      if (response.status === 200) {
        // إذا تم إضافة العنصر بنجاح، إغلاق الديالوج وإظهار رسالة نجاح
        setOpenDialog(false);
        setShowSnackbar(true);
        setSnackbarMessage("تم إضافة العنصر بنجاح");
        setSnackbarSeverity("success");
      }
    } catch (error) {
      // إذا حدث خطأ، عرض رسالة خطأ
      setShowSnackbar(true);
      setSnackbarMessage("حدث خطأ أثناء إضافة العنصر");
      setSnackbarSeverity("error");
    } finally {
      setIsProcessing(false);
    }
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setItemCode("");
    setItemCount(0);
    setItemPrice(0);
    setItemDescAr("");
    setItemDescEn("");
    setItemCategorie(1);
  };

  const handleCloseSnackbar = () => {
    setShowSnackbar(false);
  };

  return (
    <Container maxWidth="lg" style={{ direction: "rtl" }}>
      <Box
        sx={{ p: 3, bgcolor: "#ffffff", borderRadius: 1, boxShadow: 1, mb: 2 }}
      >
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Typography variant="h4">إدارة المتجر</Typography>
          <Button
            variant="contained"
            color="primary"
            onClick={() => setOpenDialog(true)}
          >
            إضافة عنصر جديد
          </Button>
        </Box>

        <TableContainer component={Paper}>
          <Table>
            <TableHead style={{ background: "#5B0052" }}>
              <TableRow>
                <TableCell style={{ color: "#f7a809", textAlign: "center" }}>
                  الصورة
                </TableCell>
                <TableCell style={{ color: "#f7a809", textAlign: "center" }}>
                  الاسم
                </TableCell>
                <TableCell style={{ color: "#f7a809", textAlign: "center" }}>
                  الوصف
                </TableCell>
                <TableCell style={{ color: "#f7a809", textAlign: "center" }}>
                  السعر بالنقاط
                </TableCell>
                <TableCell style={{ color: "#f7a809", textAlign: "center" }}>
                  رمز العنصر
                </TableCell>
                <TableCell style={{ color: "#f7a809", textAlign: "center" }}>
                  الحالة
                </TableCell>
                <TableCell style={{ color: "#f7a809", textAlign: "center" }}>
                  فئة العنصر
                </TableCell>
                <TableCell style={{ color: "#f7a809", textAlign: "center" }}>
                  الإجراءات
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {items.map((item) => (
                <TableRow key={item.item_code}>
                  <TableCell style={{ textAlign: "center" }}>
                    <img
                      src={`/images/shop/${item.item_icon}`}
                      alt={item.item_name}
                      style={{ width: 40, height: 40, objectFit: "cover" }}
                    />
                  </TableCell>
                  <TableCell style={{ textAlign: "center" }}>
                    {editItemId === item.item_code ? (
                      <TextField
                        value={
                          i18n.language === "ar"
                            ? editItemNameAr || ""
                            : editItemNameEn || ""
                        }
                        onChange={(e) =>
                          i18n.language === "ar"
                            ? setEditItemNameAr(e.target.value)
                            : setEditItemNameEn(e.target.value)
                        }
                        multiline
                        rows={4}
                        sx={{ width: "100px" }}
                        fullWidth
                      />
                    ) : i18n.language === "ar" ? (
                      item.item_name_ar
                    ) : (
                      item.item_name_en
                    )}
                  </TableCell>
                  <TableCell style={{ textAlign: "center" }}>
                    {editItemId === item.item_code ? (
                      <TextField
                        value={
                          i18n.language === "ar"
                            ? editItemDescAr || ""
                            : editItemDescEn || ""
                        }
                        onChange={(e) =>
                          i18n.language === "ar"
                            ? setEditItemDescAr(e.target.value)
                            : setEditItemDescEn(e.target.value)
                        }
                        multiline
                        rows={4}
                        sx={{ width: "100px" }}
                        fullWidth
                      />
                    ) : i18n.language === "ar" ? (
                      item.item_desc_ar
                    ) : (
                      item.item_desc_en
                    )}
                  </TableCell>
                  <TableCell style={{ textAlign: "center" }}>
                    {editItemId === item.item_code ? (
                      <TextField
                        type="number"
                        value={editItemPrice || ""}
                        onChange={(e) =>
                          setEditItemPrice(Number(e.target.value))
                        }
                      />
                    ) : (
                      item.item_point
                    )}
                  </TableCell>
                  <TableCell style={{ textAlign: "center" }}>
                    {item.item_code}
                  </TableCell>
                  <TableCell style={{ textAlign: "center" }}>
                    {editItemId === item.item_code ? (
                      <FormControl fullWidth>
                        <InputLabel>الحالة</InputLabel>
                        <Select
                          value={editItemActive}
                          onChange={(e) =>
                            setEditItemActive(Number(e.target.value))
                          }
                        >
                          <MenuItem value={1}>مفعل</MenuItem>
                          <MenuItem value={0}>غير مفعل</MenuItem>
                        </Select>
                      </FormControl>
                    ) : item.is_active === 1 ? (
                      "مفعل"
                    ) : (
                      "غير مفعل"
                    )}
                  </TableCell>

                  <TableCell style={{ textAlign: "center" }}>
                    {editItemId === item.item_code ? (
                      <FormControl fullWidth>
                        <InputLabel>الفئة</InputLabel>
                        <Select
                          value={editItemCategorie || ""}
                          onChange={(e) => setEditItemCategorie(e.target.value)}
                        >
                          {Categories.map((category) => (
                            <MenuItem
                              key={category.value}
                              value={category.value}
                            >
                              {category.name}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    ) : (
                      Categories.find(
                        (cat) => cat.value === item.item_categorie
                      )?.name
                    )}
                  </TableCell>
                  <TableCell style={{ textAlign: "center" }}>
                    {editItemId === item.item_code ? (
                      <>
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={handleSaveEdit}
                          disabled={isProcessing}
                        >
                          حفظ
                        </Button>
                        <Button
                          variant="contained"
                          color="secondary"
                          onClick={handleCancelEdit}
                        >
                          إلغاء
                        </Button>
                      </>
                    ) : (
                      <>
                        <Button
                          variant="contained"
                          color="secondary"
                          onClick={() => handleEditItem(item)}
                        >
                          تعديل
                        </Button>
                        <Button
                          variant="contained"
                          color="error"
                          onClick={() => handleDeleteItem(item.item_code)}
                        >
                          حذف
                        </Button>
                      </>
                    )}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>

      {/* Snackbar for feedback */}
      <Snackbar
        open={showSnackbar}
        autoHideDuration={3000}
        onClose={handleCloseSnackbar}
      >
        <Alert onClose={handleCloseSnackbar} severity={snackbarSeverity}>
          {snackbarMessage}
        </Alert>
      </Snackbar>

      {/* Dialog for adding new item */}
      <Dialog open={openDialog} onClose={handleCloseDialog}>
        <DialogTitle>إضافة عنصر جديد</DialogTitle>
        <DialogContent>
          <TextField
            label="رمز العنصر"
            variant="outlined"
            fullWidth
            margin="normal"
            value={itemCode}
            onChange={(e) => setItemCode(e.target.value)}
          />
          <TextField
            label="عدد العناصر"
            variant="outlined"
            fullWidth
            margin="normal"
            type="number"
            value={itemCount}
            onChange={(e) => setItemCount(Number(e.target.value))}
          />
          <TextField
            label="السعر بالنقاط"
            variant="outlined"
            fullWidth
            margin="normal"
            type="number"
            value={itemPrice}
            onChange={(e) => setItemPrice(Number(e.target.value))}
          />
          <TextField
            label="الوصف باللغة العربية"
            variant="outlined"
            fullWidth
            margin="normal"
            value={itemDescAr}
            onChange={(e) => setItemDescAr(e.target.value)}
          />
          <TextField
            label="الوصف باللغة الإنجليزية"
            variant="outlined"
            fullWidth
            margin="normal"
            value={itemDescEn}
            onChange={(e) => setItemDescEn(e.target.value)}
          />
          <FormControl fullWidth margin="normal">
            <InputLabel>فئة العنصر</InputLabel>
            <Select
              value={itemCategorie}
              onChange={(e) => setItemCategorie(e.target.value)}
            >
              {Categories.map((category) => (
                <MenuItem key={category.value} value={category.value}>
                  {category.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog} color="primary">
            إلغاء
          </Button>
          <Button
            onClick={handleAddItem}
            color="primary"
            disabled={isProcessing}
          >
            إضافة
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
};

export default Shop;
