import React, { useState } from "react";
import {
  Box,
  Card,
  CardContent,
  Typography,
  Button,
  TextField,
} from "@mui/material";
import "../i18n";
import { useTranslation } from "react-i18next";

const Category = ({ categoryName, items, onAddToCart }) => {
  const [quantity, setQuantity] = useState({});
  const { i18n, t } = useTranslation();

  const handleQuantityChange = (itemId, value) => {
    setQuantity((prevQuantity) => ({
      ...prevQuantity,
      [itemId]: value,
    }));
  };

  const handleAddToCart = (item) => {
    onAddToCart({ ...item, quantity: parseInt(quantity[item.id]) || 1 });
  };

  return (
    <Box mb={4} textAlign="center">
      <Typography
        variant="h4"
        sx={{
          background: "#f5f5f5",
          borderRadius: "8px",
          padding: "8px",
          fontWeight: 600,
          boxShadow: 2,
        }}
        gutterBottom
      >
        {categoryName}
      </Typography>
      <Box display="flex" flexWrap="wrap" gap={2} justifyContent="center">
        {items.map((item) => (
          <Card
            key={item.id}
            sx={{
              width: 350, // عرض موحد
              bgcolor: "#ffffff",
              borderRadius: 10,
              boxShadow: 3,
              margin: "10px",
              display: "flex",
              flexDirection: "row",
              overflow: "hidden",
              transition: "transform 0.3s ease, box-shadow 0.3s ease",
              "&:hover": {
                transform: "scale(1.05)",
                boxShadow: 5,
              },
            }}
          >
            {/* الجزء الأيمن - الصورة */}
            <Box
              sx={{
                width: "30%",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                padding: 1,
              }}
            >
              <Box
                component="img"
                src={`/images/shop/${item.item_icon}`}
                alt={t(item.item_name)}
                sx={{
                  width: 50,
                  height: 50,
                  objectFit: "cover",
                  borderRadius: "8px",
                  marginBottom: 1,
                }}
              />
              <Typography variant="body2" color="primary">
                {item.item_point} {t("النقاط")}
              </Typography>
              <Typography variant="body2" color="text.secondary">
                {t("الكمية")}: {item.item_count}
              </Typography>
            </Box>

            {/* الجزء الأيسر - النصوص */}
            <CardContent
              sx={{
                width: "70%",
                padding: 2,
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
              }}
            >
              <Box>
                <Typography
                  variant="h6"
                  sx={{ fontWeight: 600, marginBottom: 1 }}
                >
                  {i18n.language === "ar"
                    ? item.item_name_ar
                    : item.item_name_en}
                </Typography>
                <Typography
                  variant="body2"
                  color="text.secondary"
                  sx={{
                    fontSize: "0.875rem",
                    lineHeight: "1.5",
                    maxHeight: "4.5em", // ثلاثة أسطر (1.5em لكل سطر)
                    overflowY: "auto",
                    wordWrap: "break-word",
                    paddingRight: "4px",
                    scrollbarWidth: "thin",
                    "&::-webkit-scrollbar": {
                      width: "5px",
                    },
                    "&::-webkit-scrollbar-thumb": {
                      backgroundColor: "#888",
                      borderRadius: "4px",
                    },
                  }}
                >
                  {i18n.language === "ar"
                    ? item.item_desc_ar
                    : item.item_desc_en}
                </Typography>
              </Box>

              {/* الجزء السفلي - الزر وحقل الكمية */}
              <Box
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                sx={{
                  mt: 1.5, // تقليل المسافة
                  borderTop: "1px solid #ddd",
                  pt: 1,
                }}
              >
                <TextField
                  type="number"
                  value={quantity[item.id] || 1}
                  onChange={(e) =>
                    handleQuantityChange(item.id, e.target.value)
                  }
                  sx={{
                    width: 70,
                    "& input": {
                      textAlign: "center",
                      padding: "8px",
                    },
                    "& .MuiOutlinedInput-root": {
                      borderRadius: "20px",
                    },
                  }}
                  InputProps={{
                    inputProps: { min: 1 },
                  }}
                  placeholder={t("الكمية")}
                  size="small"
                />
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => handleAddToCart(item)}
                  sx={{
                    fontSize: "0.875rem",
                    fontWeight: "bold",
                    padding: "8px 16px",
                    borderRadius: "20px",
                    boxShadow: "0 2px 4px rgba(0, 0, 0, 0.2)",
                    transition:
                      "background-color 0.3s ease, box-shadow 0.3s ease",
                    "&:hover": {
                      backgroundColor: "#1565c0",
                      boxShadow: "0 4px 6px rgba(0, 0, 0, 0.3)",
                    },
                  }}
                >
                  {t("أضف إلى السلة")}
                </Button>
              </Box>
            </CardContent>
          </Card>
        ))}
      </Box>
    </Box>
  );
};

export default Category;
