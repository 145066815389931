import axios from "axios";

const instance = axios.create({
  // baseURL: "https://server-oddysey.onrender.com/",
  // baseURL: "http://localhost:5000/",
  baseURL:"https://serverside.rappelz-odyssey.com/",
  // baseURL: "http://5.9.215.61:5555/",
  withCredentials: true,
});

// إضافة التوكنات إلى كل طلب يتم إرساله
instance.interceptors.request.use((config) => {
  const token = localStorage.getItem("token"); // الحصول على التوكن الأول من localStorage
  const dashboardToken = localStorage.getItem("dashboardToken"); // الحصول على التوكن الثاني من localStorage

  // إضافة التوكنات إلى الهيدر إذا كانت موجودة
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }

  if (dashboardToken) {
    config.headers["Dashboard-Authorization"] = `Bearer ${dashboardToken}`;
  }

  return config;
});

export default instance;
