import React, { useState } from "react";
import {
  Container,
  TextField,
  Button,
  Typography,
  Alert,
  CircularProgress,
  Box,
} from "@mui/material";
import axios from "../utils/axiosConfig"; // Make sure axiosConfig is set up correctly
import { useTranslation } from "react-i18next";

const ForgotPassword = () => {
  const { t } = useTranslation();
  const [email, setEmail] = useState("");
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!email) {
      setError("Please enter your email address");
      return;
    }

    setLoading(true);
    setError("");
    setSuccess("");

    try {
      // Assuming the new API endpoint for password reset is "/api/forgot-password"
      await axios.post("/api/forgot-password", { email });
      setSuccess("A password recovery link has been sent to your email");
    } catch (err) {
      console.error(
        "Error submitting forgot password request:",
        err.response?.data || err.message
      );
      setError("An error occurred while sending the recovery link");
    } finally {
      setLoading(false);
    }
  };

  return (
    <Container maxWidth="xs" sx={{ height: "56vh", mt: 8, mb: 4 }}>
      <Box
        sx={{
          textAlign: "center",
          padding: 3,
          borderRadius: 2,
          boxShadow: 3,
          backgroundColor: "rgba(255, 255, 255, 0.8)",
        }}
      >
        <Typography variant="h4" gutterBottom>
          Password Recovery
        </Typography>
        {error && <Alert severity="error">{error}</Alert>}
        {success && <Alert severity="success">{success}</Alert>}
        {loading && <CircularProgress />}
        <form onSubmit={handleSubmit}>
          <TextField
            label="Email Address"
            variant="outlined"
            type="email"
            fullWidth
            margin="normal"
            required
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            sx={{ fontSize: "1.2rem" }}
            InputLabelProps={{
              style: { fontSize: "1.2rem", color: "#4B0082" },
            }}
          />
          <Button
            variant="contained"
            fullWidth
            sx={{ mt: 2 }}
            type="submit"
            disabled={loading}
          >
            {loading ? "Sending recovery link..." : "Send Recovery Link"}
          </Button>
        </form>
      </Box>
    </Container>
  );
};

export default ForgotPassword;
