import React, { useState, useEffect } from "react";
import {
  Container,
  Box,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Snackbar,
  Alert,
  Dialog,
  DialogActions,
  DialogContent,
  Button,
  DialogTitle,
  TextField,
} from "@mui/material";
import axios from "../utils/axiosConfig"; // تأكد من المسار الصحيح

const ChargeManagement = () => {
  const [charges, setCharges] = useState([]);
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("success");

  const [openEditDialog, setOpenEditDialog] = useState(false);
  const [selectedCharge, setSelectedCharge] = useState(null);

  const [editedPrice, setEditedPrice] = useState("");
  const [editedPoints, setEditedPoints] = useState("");
  const [editedItemCode, setEditedItemCode] = useState("");

  // حالات إنشاء شحن جديد
  const [openCreateDialog, setOpenCreateDialog] = useState(false);
  const [newPrice, setNewPrice] = useState("");
  const [newPoints, setNewPoints] = useState("");
  const [newItemCode, setNewItemCode] = useState("");

  useEffect(() => {
    const fetchCharges = async () => {
      try {
        const response = await axios.get("/api/charge");
        setCharges(response.data);
      } catch (error) {
        setSnackbarMessage("فشل تحميل بيانات الشحن.");
        setSnackbarSeverity("error");
        setShowSnackbar(true);
      }
    };

    fetchCharges();
  }, []);

  const handleCloseSnackbar = () => {
    setShowSnackbar(false);
  };

  const handleOpenEditDialog = (chargeItem) => {
    setSelectedCharge(chargeItem);
    setEditedPrice(chargeItem.Price);
    setEditedPoints(chargeItem.Points);
    setEditedItemCode(chargeItem.ItemCode);
    setOpenEditDialog(true);
  };

  const handleCloseEditDialog = () => {
    setOpenEditDialog(false);
    setSelectedCharge(null);
  };

  const handleEditSubmit = async () => {
    if (selectedCharge) {
      try {
        const response = await axios.put(`/api/charge/${selectedCharge.ID}`, {
          Price: editedPrice,
          Points: editedPoints,
          ItemCode: editedItemCode,
        });
        setSnackbarMessage(response.data);
        setSnackbarSeverity("success");
        setShowSnackbar(true);
        // تحديث قائمة الشحنات بعد تعديل الشحنة
        const updatedCharges = await axios.get("/api/charge");
        setCharges(updatedCharges.data);
      } catch (error) {
        setSnackbarMessage("فشل تعديل الشحنة.");
        setSnackbarSeverity("error");
        setShowSnackbar(true);
      }
    }
    handleCloseEditDialog();
  };

  const handleOpenCreateDialog = () => {
    setOpenCreateDialog(true);
  };

  const handleCloseCreateDialog = () => {
    setOpenCreateDialog(false);
    setNewPrice("");
    setNewPoints("");
    setNewItemCode("");
  };

  const handleCreateSubmit = async () => {
    try {
      const response = await axios.post("/api/charge", {
        Price: newPrice,
        Points: newPoints,
        ItemCode: newItemCode,
      });
      setSnackbarMessage(response.data);
      setSnackbarSeverity("success");
      setShowSnackbar(true);
      // تحديث قائمة الشحنات بعد إنشاء شحنة جديدة
      const updatedCharges = await axios.get("/api/charge");
      setCharges(updatedCharges.data);
    } catch (error) {
      setSnackbarMessage("فشل إنشاء الشحنة.");
      setSnackbarSeverity("error");
      setShowSnackbar(true);
    }
    handleCloseCreateDialog();
  };

  const handleDeleteItem = async (itemId) => {
    try {
      await axios.delete(`/api/charge/${itemId}`);
      setSnackbarMessage("تم حذف الشحنة بنجاح.");
      setSnackbarSeverity("success");
      setShowSnackbar(true);
      // تحديث قائمة الشحنات بعد الحذف
      const updatedCharges = await axios.get("/api/charge");
      setCharges(updatedCharges.data);
    } catch (error) {
      setSnackbarMessage("فشل حذف الشحنة.");
      setSnackbarSeverity("error");
      setShowSnackbar(true);
    }
  };

  return (
    <Container maxWidth="lg" style={{ direction: "rtl" }}>
      <Box sx={{ p: 3, bgcolor: "#ffffff", borderRadius: 1, boxShadow: 1, mb: 2 }}>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Typography variant="h4" gutterBottom>
            إدارة الشحنات
          </Typography>
          <Button
            variant="contained"
            color="primary"
            onClick={handleOpenCreateDialog}
            sx={{ mb: 2 }}
          >
            إنشاء شحنة جديدة
          </Button>
        </Box>
        <TableContainer component={Paper}>
          <Table>
            <TableHead style={{ background: "#5B0052" }}>
              <TableRow>
                <TableCell style={{ color: "#f7a809", textAlign: "center" }}>
                  ID
                </TableCell>
                <TableCell style={{ color: "#f7a809", textAlign: "center" }}>
                  السعر
                </TableCell>
                <TableCell style={{ color: "#f7a809", textAlign: "center" }}>
                  النقاط
                </TableCell>
                <TableCell style={{ color: "#f7a809", textAlign: "center" }}>
                  رمز العنصر
                </TableCell>
                <TableCell style={{ color: "#f7a809", textAlign: "center" }}>
                  الإجراءات
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {charges.map((item) => (
                <TableRow key={item.ID}>
                  <TableCell style={{ textAlign: "center" }}>
                    {item.ID}
                  </TableCell>
                  <TableCell style={{ textAlign: "center" }}>
                    {item.Price}
                  </TableCell>
                  <TableCell style={{ textAlign: "center" }}>
                    {item.Points}
                  </TableCell>
                  <TableCell style={{ textAlign: "center" }}>
                    {item.ItemCode}
                  </TableCell>
                  <TableCell
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "center",
                      textAlign: "center",
                    }}
                  >
                    <Button
                      color="info"
                      variant="contained"
                      onClick={() => handleOpenEditDialog(item)}
                    >
                      تعديل
                    </Button>
                    <Button
                      color="error"
                      variant="contained"
                      onClick={() => handleDeleteItem(item.ID)}
                    >
                      حذف
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>

      <Snackbar
        open={showSnackbar}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
      >
        <Alert onClose={handleCloseSnackbar} severity={snackbarSeverity}>
          {snackbarMessage}
        </Alert>
      </Snackbar>

      {/* Dialog for editing charge */}
      <Dialog open={openEditDialog} onClose={handleCloseEditDialog}>
        <DialogTitle>تعديل الشحنة</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            label="السعر"
            type="number"
            fullWidth
            variant="outlined"
            value={editedPrice}
            onChange={(e) => setEditedPrice(e.target.value)}
          />
          <TextField
            margin="dense"
            label="النقاط"
            type="number"
            fullWidth
            variant="outlined"
            value={editedPoints}
            onChange={(e) => setEditedPoints(e.target.value)}
          />
          <TextField
            margin="dense"
            label="رمز العنصر"
            type="text"
            fullWidth
            variant="outlined"
            value={editedItemCode}
            onChange={(e) => setEditedItemCode(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleEditSubmit} color="primary">
            تعديل الشحنة
          </Button>
          <Button onClick={handleCloseEditDialog} color="secondary">
            إغلاق
          </Button>
        </DialogActions>
      </Dialog>

      {/* Dialog for creating new charge */}
      <Dialog open={openCreateDialog} onClose={handleCloseCreateDialog}>
        <DialogTitle>إنشاء شحنة جديدة</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            label="السعر"
            type="number"
            fullWidth
            variant="outlined"
            value={newPrice}
            onChange={(e) => setNewPrice(e.target.value)}
          />
          <TextField
            margin="dense"
            label="النقاط"
            type="number"
            fullWidth
            variant="outlined"
            value={newPoints}
            onChange={(e) => setNewPoints(e.target.value)}
          />
          <TextField
            margin="dense"
            label="رمز العنصر"
            type="text"
            fullWidth
            variant="outlined"
            value={newItemCode}
            onChange={(e) => setNewItemCode(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCreateSubmit} color="primary">
            إنشاء الشحنة
          </Button>
          <Button onClick={handleCloseCreateDialog} color="secondary">
            إغلاق
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
};

export default ChargeManagement;
