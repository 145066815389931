import React, { useState, useEffect } from "react";
import {
  Box,
  Tabs,
  Tab,
  Container,
  Typography,
  Paper,
  CircularProgress,
  Button,
  TextField,
  Alert,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import axios from "../utils/axiosConfig";
import Players from "./Players";
import Shop from "./Shop";
import Links from "./Accounts";
import News from "./News";
import Statistics from "./Statistics";
import Items from "./Items";
import ChargeManagement from "./ChargeManagement";
import Logs from "./Logs";
import CouponManagement from "./CouponManagement";

const Dashboard = () => {
  const [selectedTab, setSelectedTab] = useState("statistics");
  const [loading, setLoading] = useState(true);
  const [verificationCode, setVerificationCode] = useState("");
  const [isCodeSent, setIsCodeSent] = useState(false);
  const [isCodeValid, setIsCodeValid] = useState(null);
  const [errorMessage, setErrorMessage] = useState("");
  const [userVerified, setUserVerified] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [resendCountdown, setResendCountdown] = useState(0);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchUserData = async () => {
      const token = localStorage.getItem("token");
      const dashboardToken = localStorage.getItem("dashboardToken");

      // إذا كان هناك dashboardToken، هذا يعني أن المستخدم قد تم التحقق منه
      if (dashboardToken) {
        setUserVerified(true);
        setLoading(false);
        return;
      }

      // إذا لم يكن هناك توكن أساسي (token)، نعيد تحميل الصفحة
      if (!token) {
        navigate("/login");
        return;
      }

      try {
        const response = await axios.get("/api/checkAuth", {
          headers: { Authorization: `Bearer ${token}` },
        });

        setLoading(false);

        // إذا كانت الاستجابة تحتوي على removeToken، يعني أن التوكن غير صالح
        if (response.data.removeToken) {
          // إزالة dashboardToken من localStorage في حال كان غير صالح
          localStorage.removeItem("dashboardToken");
          navigate("/login");
          return;
        }

        // التحقق من صلاحيات المستخدم، وإذا لم تكن كافية نعيد توجيهه لصفحة تسجيل الدخول
        if (response.data.permission !== 500) {
          navigate("/login");
          return;
        }

        // تحديث حالة التحقق من المستخدم إذا كان التحقق ناجحاً
        setUserVerified(response.data.isVerified);

        // إذا كان المستخدم مفعل، نقوم بتخزين dashboardToken في localStorage
        if (response.data.isVerified) {
          localStorage.setItem("dashboardToken", response.data.dashboardToken);
        }
      } catch (error) {
        console.error("Error fetching user data:", error);

        // إذا كان الخطأ بسبب انتهاء صلاحية التوكن (401)
        if (error.response && error.response.status === 401) {
          localStorage.removeItem("token");
          localStorage.removeItem("dashboardToken");
          navigate("/login"); // توجيه المستخدم إلى صفحة تسجيل الدخول
        } else {
          window.location.reload(); // في حال حدوث أي خطأ آخر، نقوم بإعادة تحميل الصفحة
        }
      }
    };

    fetchUserData();
  }, [navigate]);

  useEffect(() => {
    if (resendCountdown > 0) {
      const timer = setTimeout(
        () => setResendCountdown(resendCountdown - 1),
        1000
      );
      return () => clearTimeout(timer);
    }
  }, [resendCountdown]);

  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  const handleSendVerificationCode = async () => {
    const token = localStorage.getItem("token");

    if (!token) {
      setErrorMessage("يجب تسجيل الدخول للحصول على رمز التحقق.");
      return;
    }

    try {
      setIsLoading(true);
      await axios.post(
        "/api/checkAuth/sendCode",
        {},
        { headers: { Authorization: `Bearer ${token}` } }
      );
      setIsCodeSent(true);
      setErrorMessage("");
      setResendCountdown(60);
    } catch (error) {
      console.error("Error sending verification code:", error);
      setErrorMessage("فشل في إرسال رمز التحقق. حاول مرة أخرى.");
    } finally {
      setIsLoading(false);
    }
  };

  const handleVerifyCode = async () => {
    const token = localStorage.getItem("token");

    if (!token) {
      setErrorMessage("يجب تسجيل الدخول للتحقق من الرمز.");
      return;
    }

    try {
      setIsLoading(true);
      const response = await axios.post(
        "/api/checkAuth/verifyCode",
        { code: verificationCode },
        { headers: { Authorization: `Bearer ${token}` } }
      );

      if (response.data.dashboardToken) {
        setIsCodeValid(true);
        setErrorMessage("");
        setUserVerified(true);
        localStorage.setItem("dashboardToken", response.data.dashboardToken);
      } else {
        setIsCodeValid(false);
        setErrorMessage("رمز التحقق غير صحيح. حاول مرة أخرى.");
      }
    } catch (error) {
      console.error("Error verifying the code:", error);
      setIsCodeValid(false);
      setErrorMessage("رمز التحقق غير صحيح. حاول مرة أخرى.");
    } finally {
      setIsLoading(false);
    }
  };

  const renderContent = () => {
    switch (selectedTab) {
      case "players":
        return <Players />;
      case "shop":
        return <Shop />;
      case "accounts":
        return <Links />;
      case "news":
        return <News />;
      case "statistics":
        return <Statistics />;
      case "items":
        return <Items />;
      case "charge":
        return <ChargeManagement />;
      case "coupon":
        return <CouponManagement />;
      case "logs":
        return <Logs />;
      default:
        return <Statistics />;
    }
  };

  if (loading) {
    return (
      <Container
        maxWidth="lg"
        style={{ textAlign: "center", marginTop: "20%" }}
      >
        <CircularProgress />
        <Typography variant="h6" sx={{ mt: 2 }}>
          جاري تحميل البيانات...
        </Typography>
      </Container>
    );
  }

  return (
    <Container maxWidth="lg" style={{ direction: "rtl" }} sx={{ mt: 4 }}>
      <Paper
        sx={{
          borderBottom: 1,
          borderColor: "divider",
          mb: 2,
          backgroundColor: "#1976d2",
        }}
      >
        <Tabs
          value={selectedTab}
          onChange={handleTabChange}
          aria-label="tabs"
          textColor="inherit"
          indicatorColor="secondary"
        >
          <Tab
            value="statistics"
            label="الإحصائيات"
            sx={{ color: "#ffffff" }}
          />
          <Tab value="players" label="الشخصيات" sx={{ color: "#ffffff" }} />
          <Tab value="accounts" label="الحسابات" sx={{ color: "#ffffff" }} />
          <Tab value="items" label="العناصر" sx={{ color: "#ffffff" }} />
          <Tab value="shop" label="المتجر" sx={{ color: "#ffffff" }} />
          <Tab value="news" label="الأخبار" sx={{ color: "#ffffff" }} />
          <Tab value="charge" label="إدارة الشحنات" sx={{ color: "#ffffff" }} />
          <Tab
            value="coupon"
            label="إدارة الكوبونات"
            sx={{ color: "#ffffff" }}
          />
          <Tab value="logs" label="السجلات" sx={{ color: "#ffffff" }} />
        </Tabs>
      </Paper>

      <Box sx={{ p: 3, bgcolor: "#f5f5f5", borderRadius: 1 }}>
        {userVerified ? (
          renderContent()
        ) : (
          <Box sx={{ textAlign: "center" }}>
            <Typography variant="h6">التحقق الثنائي مطلوب</Typography>
            {!isCodeSent && (
              <Button
                variant="contained"
                color="primary"
                onClick={handleSendVerificationCode}
                sx={{ mt: 2 }}
                disabled={isLoading}
              >
                {isLoading ? (
                  <CircularProgress size={24} />
                ) : (
                  "إرسال رمز التحقق"
                )}
              </Button>
            )}
            {isCodeSent && (
              <Box sx={{ mt: 2 }}>
                <TextField
                  label="رمز التحقق"
                  value={verificationCode}
                  onChange={(e) => setVerificationCode(e.target.value)}
                  sx={{ mb: 2 }}
                  fullWidth
                />
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={handleVerifyCode}
                  disabled={isLoading}
                >
                  {isLoading ? (
                    <CircularProgress size={24} />
                  ) : (
                    "التحقق من الرمز"
                  )}
                </Button>
                {resendCountdown > 0 ? (
                  <Typography variant="body2" sx={{ mt: 1, color: "gray" }}>
                    يمكنك إعادة إرسال الرمز بعد{" "}
                    <strong>{resendCountdown} ثانية</strong>.
                  </Typography>
                ) : (
                  <Button
                    variant="text"
                    color="primary"
                    onClick={handleSendVerificationCode}
                  >
                    إعادة إرسال الرمز
                  </Button>
                )}
              </Box>
            )}
            {isCodeValid === false && (
              <Alert severity="error" sx={{ mt: 2 }}>
                رمز التحقق غير صحيح. حاول مرة أخرى.
              </Alert>
            )}
            {errorMessage && (
              <Alert severity="error" sx={{ mt: 2 }}>
                {errorMessage}
              </Alert>
            )}
          </Box>
        )}
      </Box>
    </Container>
  );
};

export default Dashboard;
